import React from "react";
import { Col, Row } from "react-bootstrap";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const GiftCardPurchasedPage = () => {
  const data = useStaticQuery(graphql`
    query {
      giftCards: file(relativePath: { eq: "gift-cards-01.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Purchase official gift cards for Taplist.io" />
      <section className="section" style={{ paddingTop: 0 }}>
        <Row>
          <Col className="text-center">
            <h1>Your Purchase is Complete!</h1>
          </Col>
        </Row>
        <Row>
          <Col span={{ md: 8, offset: 2 }}>
            <Img
              fluid={data.giftCards.childImageSharp.fluid}
              alt="Several Taplist.io gift cards"
            />
            <p className="lead text-center">
              Thank you for your Gift Card purchase! Please check your email
              in a moment for your gift card details.
            </p>
            <p className="text-center">
              <em>Didn't get an email? Please drop us a line at <b>support@taplist.io</b> and we can help you out!</em>
            </p>
          </Col>
        </Row>
      </section>
    </Layout>
  );
};

export default GiftCardPurchasedPage;
